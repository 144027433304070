.SingleDatePicker .SingleDatePickerInput {
  height: 37px;
  border: none;
  border-radius: 0;
}

.SingleDatePicker .SingleDatePickerInput .DateInput {
  height: 37px;
}

.SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input {
  height: 37px;
  padding-right: 35px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input::placeholder {
  font-style: italic;
  opacity:1;
  color: black;
}

.SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input:not(.DateInput_input__disabled) {
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: rgba(0, 0, 0, 0.87);
  
}

.SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input.DateInput_input__disabled {
  border: 1px solid rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.38);
  
}

.SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input:not(.DateInput_input__disabled):hover {
  border-color: rgb(0, 0, 0);
}

.DateInput_input__disabled  {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.38);
}

.SingleDatePicker .CalendarDay__selected {
  background: #000000;
}

.SingleDatePicker .CalendarDay__selected:focus {
  outline: none;
  border: 1px solid #e4e7e7;
}
